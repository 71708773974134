.asset-cta-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  .asset-action-buttons {
    button {
      margin-left: 0.5rem;
    }
  }
}

.single-asset-view-container {
  position: relative;
  .close-asset-view-btn {
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 20px;
    border-radius: 5px;
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
  }
}
