.asset-map {
  position: relative;
  .no-map-data-container {
    height: 100%;
    width: 100%;
    background-color: rgba($color: #333, $alpha: 0.5);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    margin: auto;
  }
  .no-map-data {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #999;
    background-color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    margin: auto;
    width: 50%;
    height: 25%;
    border-radius: 5px;
    border: 1px solid #2c9912;
    h1 {
      margin-bottom: 0;
    }
  }
}
