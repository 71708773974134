.reconcile-button {
  text-align: center;
  display: flex;
  color: #5a5a5a;
  background: #e5e5e5;
  border: #5a5a5a;
}
.reconcile-button:hover {
  background: #c5c5c5;
}
.resolve-button {
  text-align: center;
  display: flex;
  margin-top: 1rem;
  margin-left: 1rem;
}
.asset-location-history-tabs {
  // background-color: #fff;
  // padding: 0.5rem;
  // border: 1px solid #2c9912;
  // border-radius: 5px;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .asset-reconcile-location {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 0.5rem;
      &.pending-approval-button {
        .anticon-bell {
          color: #fff;
        }
      }
    }
  }
}

.asset-resolve-reconciliation {
  // background-color: #fff;
  // padding: 0.5rem;
  // border: 1px solid #2c9912;
  // border-radius: 5px;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .asset-resolve-button {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 0.5rem;
      &.pending-approval-button {
        .anticon-bell {
          color: #fff;
        }
      }
    }
  }
}
