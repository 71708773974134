footer {
  background-color: transparent;
  .footer-container {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    p {
      margin: 0;
      margin-left: 0.5rem;
      margin-left: 0.5rem;
    }
    img {
      margin: 0.1rem;
      margin-left: 0.5rem;
    }
  }
}
