.not-found-page {
  height: calc(100% - 64px);
  width: 100%;
  position: relative;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(217deg, rgba(75, 83, 255, 0.15), rgba(21, 21, 30, 0.15), rgba(21, 21, 30, 0) 70.71%),
    linear-gradient(127deg, rgba(236, 29, 36, 0.15), rgba(21, 21, 30, 0.15), rgba(21, 21, 30, 0) 70.71%),
    linear-gradient(336deg, rgba(21, 21, 30, 0.1), rgba(0, 0, 255, 0) 70.71%);

  .cta-container {
    width: 100%;
    position: relative;
    z-index: 1;
    margin: auto;
    margin-top: 40px;
    text-align: center;
    display: block;
    button {
      margin-right: 10px;
      display: inline-block;
    }
  }

  .block-container {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  h1 {
    font-size: 10rem;
    height: 100%;
    line-height: inherit;
  }
  h2 {
    vertical-align: middle;
  }
  h1,
  h2 {
    color: var(--color-text);
  }
}
