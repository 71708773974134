.ant-card {
  &.downloader-container {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    .ant-card-head-title {
      text-align: left;
    }
    .ant-card-body {
      display: block;
      justify-content: center;
    }
    .download-item {
      display: flex;
      width: 100%;
      height: 60px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      @apply border border-solid border-ncr-brand-dark-teal;
      background-color: #fff;
      justify-content: space-between;
      border-radius: 5px;
      padding: 0;
      cursor: pointer;
      margin: auto;
      &.ant-tooltip-disabled-compatible-wrapper {
        cursor: not-allowed;
        &:hover {
          .icon-box {
            background-color: transparent;
            svg {
              @apply fill-ncr-brand-dark-teal;
            }
          }
          .icon-download {
            svg {
              fill: #9b9b9b;
            }
          }
        }
      }
      button {
        display: flex;
        width: 300px;
        height: 60px;
        justify-content: space-between;
        padding: 0;
        border: none;
      }
      &:hover {
        @apply border border-solid border-ncr-brand-dark-teal;
        .icon-box {
          @apply bg-ncr-brand-dark-teal;
          svg {
            fill: #fff;
          }
        }
        .icon-download {
          svg {
            @apply fill-ncr-brand-dark-teal
          }
        }
      }

      .icon-box {
        background-color: transparent;
        padding: 15px;
        transition: background-color 0.5s ease-in-out;
        position: relative;
        border-radius: 5px 0px 0px 5px;
        &:after {
          content: '';
          display: block;
          clear: both;
          width: 0.5px;
          height: 60%;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #9b9b9b;
          opacity: 0.5;
        }
        svg {
          transition: fill 0.5s ease-in-out;
          height: 1.5rem;
          width: 1.5rem;
          @apply fill-ncr-brand-dark-teal;
          margin: auto;
        }
      }
      .icon-data {
        h3 {
          line-height: 3.5rem;
        }
      }
      .icon-download {
        padding: 1rem;
        svg {
          transition: fill 0.5s ease-in-out;
          height: 1.5rem;
          width: 1.5rem;
          fill: #9b9b9b;
          margin: auto;
        }
      }
    }
  }
}
