.AddContractButton {
  display: flex;
  justify-content: flex-end;
}
.contractsTitle {
}
.gridHeader {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
}
.addContract {
  display: none;
}
.xButton {
  font-size: 30px;
  display: flex;
  margin-left: 140px;
  color: #5a5a5a;
  margin-bottom: 1px;
  background: none;
  border: none;
}
.xButton:hover {
  color: #000000;
}
