@use './assets/scss/tailwind.scss';
@use './assets/scss/base.scss';
@use './assets/scss/ant-customizations.scss';

$primary-color: #D1E0D7;

::-moz-selection {
    background: $primary-color !important;
    color: inherit !important;
  }

  ::selection {
    color: inherit !important;
    background: $primary-color !important;
  }
