.ant-menu {
  align-items: center;
  &.client-nav {
    .ant-menu-item {
      &:first-child {
        pointer-events: none;
        cursor: pointer;
      }
    }
  }

  .ant-menu-item {
    &:last-of-type {
      margin-left: auto;
    }
    .company-name {
      pointer-events: none;
      cursor: pointer;
      span {
        color: #333;
        font-size: 1.5rem;
        margin-bottom: 0;
      }
    }
  }
}
