.asset-actions {
  .ant-list-items {
    .ant-list-item {
      &.action-item {
        border: 1px solid #cf1322;
        border-bottom: 1px solid #cf1322;
        padding: 1rem;
        justify-content: flex-start;

        p {
          padding: 1rem;
          margin-bottom: 0;
        }
        .action-required {
        }
        .action-title {
        }
        .action-description {
        }
        .action-atts {
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin: 0 0.5rem;

          .ant-upload-list-item {
            margin: 0;
          }
          .col {
            display: inline-block;
            margin: 0.5rem;
          }
        }
        .action-missing {
          margin: 0 0.5rem;
        }
      }
    }
  }
}
