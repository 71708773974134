.top-banner {
  @apply bg-white;
  padding: 1rem;
  .tracking-tight {
    display: none;
  }

  .flex {
    align-items: center;
    padding: 1rem 4rem;
    padding-left: 1rem;
    h1 {
      margin-bottom: 0;
    }
  }
  .logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    .logo-text {
      margin-bottom: 0;
    }
  }
}
.ant-layout-header {
  margin-bottom: 2rem;
  .top-banner {
    display: flex;
    justify-content: flex-start;
    .menu-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .ant-menu {
        a {
          @apply text-ncr-brand-dark-teal;
        }
        &.main-nav {
          background: transparent;
          box-shadow: none;
          border: none;
          width: auto;
          justify-content: flex-start;
          margin: 0;
          padding: 0;
          line-height: 2rem;
          width: 715px;
          a {
            &.active {
              &::after {
                content: '';
                @apply border-b border-solid border-ncr-brand-dark-teal;
                bottom: 0px;
                position: absolute;
                right: 20px;
                left: 20px;
                transition: border-color 0.3s
                  cubic-bezier(0.645, 0.045, 0.355, 1);
              }
            }
          }
          &.sub-menu {
            width: auto;
          }
        }
      }
    }
    .sub-menu-container {
      .ant-menu {
        padding: 0;
        margin: 0;
        line-height: 2rem;
        background-color: transparent;
        box-shadow: none;
        border: none;
      }
    }
  }
}

header.ant-layout-header {
  height: auto;
  background-color: transparent;
  padding: 0;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
