.AssetPropsList {
  font-size: 0.8rem;
  color: #5a5a5a;
  background: #ffffff;
  border: none;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    display: flex;
    column-gap: 1rem;
  }
  span {
    font-weight: bold;
  }
  * {
    white-space: break-spaces;
  }
}

.flag-tags {
  display: flex;
  flex-wrap: wrap;
  p {
    margin: 0;
  }
  .ant-tag {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.asset-cta-toolbar {
  margin-bottom: 1rem;
  flex-wrap: wrap;
  .asset-action-buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    button {
      margin-left: 0.5rem;
      &.pending-approval-button {
        .anticon-bell {
          color: #fff;
        }
      }
    }
  }
}
