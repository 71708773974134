.UserAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  svg {
    position: relative;
    top: -3px;
  }
}
.gridHeader {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
}
