.sliamuploader-collapse.ant-collapse {
  background-color: transparent !important;
  border: none !important;
}

.sliamuploader-collapse.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.sliamuploader-collapse.ant-collapse-content {
  border-top: none !important;
}

.sliamuploader-collapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  > .ant-collapse-expand-icon
  > span {
  display: none;
}
