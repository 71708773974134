.buttonsList {
  line-height: 378%;
}
.ant-btn {
  &.service-btn {
    font-size: 33px;
    text-align: left;
    color: #6b6b6b;
    &:hover {
      color: #000000;
      background: transparent;
    }
    &:focus {
      color: #000000;
      background: transparent;
      text-underline: #000000;
      text-underline-position: auto;
    }
  }
}

.dashboard-tiles {
  display: flex;
  flex-wrap: wrap;
  .ant-card {
    flex: 1 0 calc(5% - 10px);
    margin: 5px;
    box-sizing: border-box;
    text-align: center;
    transition: all 0.5s ease-in-out;
    max-height: 160px;
    &:hover {
      cursor: pointer;
      box-shadow: rgba(149, 157, 165, 0.6) 0px 8px 24px;
    }
    &.leaks-card {
      &:hover {
        border-color: #d4380d;
      }
    }
    &.moved-card {
      &:hover {
        border-color: #389e0d;
      }
    }

    &.pending-card {
      &:hover {
        border-color: #d4b106;
      }
    }
    &.total-card {
      &:hover {
        border-color: #096dd9;
      }
    }
    @media only screen and (max-width: 768px) {
      flex: 1 0 calc(50% - 10px);
    }
    @media only screen and (max-width: 480px) {
      flex: 1 0 calc(100%);
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.custom-tag {
  &.ant-tag {
    font-size: 1.2rem;
    margin-left: 1rem;
    padding: 0.5rem;
  }
}
