.client-dashboard {
  .client-header {
    @apply px-2 py-4 bg-ncr-brand-light-teal shadow-md rounded-md;
    margin-bottom: 1rem;
    h1 {
      @apply pl-5 text-ncr-brand-dark-teal font-medium;
      margin-bottom: 0;
      min-height: 33px;
      display: inline-block;
    }
    h2 {
      @apply text-sm font-light text-ncr-brand-dark-teal;
      margin-bottom: 0;
      padding-left: 1rem;
      display: inline-block;
    }
  }
  .main-nav {
    @apply bg-transparent border-none;
    //@apply bg-ncr-brand-light-teal shadow-md rounded-md;
    .ant-menu-item {
      &::after {
        bottom: 7px;
      }
      &:hover {
        &::after {
          @apply border-solid border-b border-ncr-brand-dark-teal;
        }
      }
      a {
        @apply text-ncr-brand-dark-teal;
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        &.active {
          &::after {
            content: '';
            @apply border-solid border-b border-ncr-brand-dark-teal;
            bottom: 7px;
            position: absolute;
            right: 20px;
            left: 20px;
            transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
        }
        &:hover {
          @apply text-ncr-brand-dark-teal;
        }
      }
    }
  }
  .breadcrumb {
    margin-bottom: 1rem;
  }
  .dashboard-outlet {
    .ant-breadcrumb {
      display: none;
    }
  }
}
