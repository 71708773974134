.SitePropsList {
  font-size: 1rem;
  color: #5a5a5a;
  background: #ffffff;
  border: none;
  span {
    font-weight: bold;
  }
}
.updateSiteButton {
  text-align: center;
  display: flex;
  color: #5a5a5a;
  background: #e5e5e5;
  border: #5a5a5a;
}
.updateSiteButton:hover {
  background: #c5c5c5;
}

.site-cta-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  .site-action-buttons {
    button {
      margin-left: 0.5rem;
    }
  }
}

.single-site-view-container {
  position: relative;
  .close-site-view-btn {
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 20px;
    border-radius: 5px;
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
  }
}
