.my-profile-header {
  color: #5a5a5a;
  font-size: 40px;
  margin-top: 10px;
}
.user-props-list {
  font-size: 1rem;
  color: #5a5a5a;
  background: #FFFFFF;
  border: none;
  line-height: 130%;
  margin-left: 10px;
  margin-top: 50px;
  margin-bottom: 64px;
}

li:not(:last-child) {
  margin-bottom: 5px;
}
