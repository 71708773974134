.loader {
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: block;
  &.inside {
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .ant-spin-nested-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    margin: auto;
    height: 100px;
    width: 100px;
  }
}
