.UpdatePropsList {
  font-size: 20px;
  color: #5a5a5a;
  background: #ffffff;
  border: none;
  line-height: 170%;
}

.saveButton {
  font-size: 20px;
  text-align: center;
  display: flex;
  margin-left: 140px;
  color: #5a5a5a;
  marginbottom: 1px;
  background: #e5e5e5;
  border: #5a5a5a;
}
.saveButton:hover {
  background: #c5c5c5;
}
.backButton {
  margin-bottom: 15px;
  margin-top: 10px;
}
.pendingTasksButton {
  margin-bottom: 15px;
  margin-top: 31px;
}
.updateProps {
  display: none;
}
.updateInput {
  margin-bottom: 5px;
}
.breadcrumb {
  margin-bottom: 1rem;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.navMenu {
  background: #5a5a5a;
}
