.login-page {
  @apply bg-cover bg-darkgreen bg-no-repeat bg-ncr-brand-light-teal;
  display: flex;
  flex: 1;
  h1 {
    margin-bottom: 3rem;
  }
  .logo {
    position: absolute;
    top: 0;
    left: 1rem;
    right: 0;
  }
  .banner-container {
    background-image: linear-gradient(
        rgba(47, 52, 88, 0.4),
        rgba(47, 52, 88, 0.6)
      ),
      url('../../assets/images/login-bg.jpg');
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 55%;
    position: relative;
    border-radius: 26px 0px 25px 0px;
    margin: 1rem;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .logo-banner {
      position: absolute;
      bottom: 1rem;
      right: 2rem;
      width: 4rem;
      height: 4rem;
    }
    .banner-content {
      background-color: white;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: fit-content;
      max-width: 50%;
      height: fit-content;
      margin: auto;
      border-radius: 10px;
      padding: 2rem;
      @media screen and (max-width: 1024px) {
        display: none;
      }

      h1 {
        font-size: 3.2rem;
        width: 80%;
        line-height: normal;
        &::after {
          content: '';
          display: block;
          width: 30%;
          height: 0.8rem;
          background-color: #54b948;
          margin-top: 1rem;
        }
      }
      h2 {
        font-size: 1rem;
      }
    }
  }
  .container {
    display: flex;
    min-height: 100vh;
    @apply w-full justify-center align-middle;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .form {
    @apply bg-white p-16 w-full max-w-lg m-auto rounded-lg;
  }
  .register-container {
    .button-ctas {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
    }
  }
}
